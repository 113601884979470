.vehiclelist {
  margin-bottom: 40px;
}

.vehiclelist table {
  border-top: none !important; /* SDX */
}

.vehiclelist table thead td {
  padding-bottom: 17px;
}

.vehiclelist table tbody tr:not(.disabled) {
  transition: background-color 200ms;
  cursor: pointer;
}

.vehiclelist table tbody tr:hover {
  background-color: var(--color-horizon-40);
}

.vehiclelist table tbody tr.active {
  background-color: var(--color-horizon);
}

.vehiclelist table tbody tr.disabled {
  background-color: var(--color-input-disabled-bg);
  cursor: default;
}

.vehiclelist table tbody td {
  white-space: normal;
}

.selectedIcon {
  font-size: 24px !important;
}
