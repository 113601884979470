.oldDate {
  display: inline-block;
  color: #ff8b2e;
  text-decoration: line-through;
}

.newDate {
  display: inline-block;
  margin-left: 10px;
}
