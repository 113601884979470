.errorWrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerError {
  width: 800px;
}

.innerError p {
  text-align: center;
}

.errorId {
  margin-top: 20px;
}
