.container {
  width: 75%;
  padding: 32px
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
}

.categoryCardsContainer{
  display: flex;
  flex-wrap: wrap;
}

.card{
  min-height: 100%;
}

.backbutton{
  color: var(--color-navy);
  cursor: pointer;
  margin-bottom: 16px;
}

.backbutton span {
  margin-left: 8px;
}
