.loadingWrapper {
  display: flex;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 80px;
}
.statusCard {
  width: calc(100% / 3 * 2) !important;
  margin: 0 !important;

  overflow: visible !important;
}

.maxHeight{
  max-height: 800px;
  overflow: scroll;
}
