.row:hover {
  background-color: var(--color-horizon);
  cursor: pointer;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot_flashing {
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: var(--color-horizon);
  color: var(--color-horizon);
  -webkit-animation: dot_flashing 1.5s infinite linear;
  animation: dot_flashing 1.5s infinite linear;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.dot_flashing::before,
.dot_flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot_flashing::before {
  left: -7px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: var(--color-horizon);
  color: var(--color-horizon);
  -webkit-animation: dot_flashing 1.5s infinite;
  animation: dot_flashing 1.5s infinite;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.dot_flashing::after {
  left: 7px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: var(--color-horizon);
  color: var(--color-horizon);
  -webkit-animation: dot_flashing 1.5s infinite;
  animation: dot_flashing 1.5s infinite;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@-webkit-keyframes dot_flashing {
  0% {
    background-color: var(--color-horizon);
  }
  40% {
    background-color: var(--color-gray-40);
  }
  80% {
    background-color: var(--color-horizon);
  }
  100% {
    background-color: var(--color-horizon);
  }
}

@keyframes dot_flashing {
  0% {
    background-color: var(--color-horizon);
  }
  40% {
    background-color: var(--color-gray-40);
  }
  80% {
    background-color: var(--color-horizon);
  }
  100% {
    background-color: var(--color-horizon);
  }
}
