.flex {
  display: flex;
}

.flex-space-between {
  justify-content: space-between;
  margin-bottom: 15px;
}

.flex-items-center {
  align-items: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-col {
  flex-direction: column;
}

.font-size-default {
  font-size: 18px !important;
}

.font-weight-semi-bold {
  font-weight: 600;
}

.line-through {
  text-decoration: line-through;
}

/* For using datepickers in modals => Modals have z-index 900001 */
.flatpickr-calendar {
  z-index: 900002 !important;
}

.flatpickr-calendar .flatpickr-disabled {
  color: var(--color-gray-60);
}

.deleteDraftButton {
  background-color: var(--color-sc-red) !important;
}

.center {
  display: flex;
  align-items: center;
}

.errorContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
