.container {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: var(--color-int-red);
  margin-top: 6px;
}

.container i {
  margin-right: 4px;
}
