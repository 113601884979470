:root {
  /* //
  // Color Variables
  // --------------------------------------------------

  // Core Colors
  // --------------------------------------------------

  // White */
  --color-white: #fff;

  /* // Navy */
  --color-navy: #015;

  /* // Blue */
  --color-blue: #1781e3;
  --color-blue-40: #a2cdf4;
  --color-blue-20: #d1e6f9;

  /* // Aluminium */
  --color-aluminium: #dde3e7;
  --color-aluminum: var(--color-aluminium);
  --color-aluminium-80: #e4e9ec;
  --color-aluminum-80: var(--color-aluminium-80);

  /* // Horizon */
  --color-horizon: #eef3f6;
  --color-horizon-40: #f8fafb;

  /* // Dark gray */
  --color-gray: #333;
  --color-gray-90: #474747;
  --color-gray-80: #5c5c5c;
  --color-gray-60: #858585;
  --color-gray-40: #adadad;
  --color-gray-20: #d6d6d6;

  /* // Mid gray */
  --color-gray-mid: #666;

  /* // Light gray */
  --color-gray-light: #bbb;

  /* // Brand Colors
  // -------------------------------------------------- */

  /* // Swisscom Blue */
  --color-sc-blue: #1af;

  /* // Swisscom Red */
  --color-sc-red: #d12;

  /* // Swisscom Purple */
  --color-sc-purple: #516;

  /* // Swisscom Navy */
  --color-sc-navy: var(--color-navy);

  /* // Swisscom White */
  --color-sc-white: var(--color-white);

  /* // Swisscom Dark gray */
  --color-sc-gray: var(--color-gray);

  /* // Swisscom Mid gray */
  --color-sc-gray-mid: var(--color-gray-mid);

  /* // Swisscom Light gray */
  --color-sc-gray-light: var(--color-gray-light);

  /* // Interaction Colors
  // --------------------------------------------------

  // Interaction Blue */
  --color-int-blue: #1781e3;
  /* // Interaction Blue Active */
  --color-int-blue--active: #0851da;

  /* // Interaction Gray */
  --color-int-gray: #cfd5d9;
  /* // Interaction Gray Active */
  --color-int-gray--active: #b1b9be;

  /* // Interaction Green */
  --color-int-green: #25b252;
  /* // Interaction Green Active */
  --color-int-green--active: #008236;

  /* // Interaction Red */
  --color-int-red: #d12;
  /* // Interaction Red Active */
  --color-int-red--active: #be0000;

  /* // Interaction Orange */
  --color-int-orange: #ff8b2e;
  /* // Interaction Orange Active */
  --color-int-orange--active: #e86416;

  /* // Content Colors */
  /* // -------------------------------------------------- */
  /*  */
  /* // Orchid */
  --color-orchid: #a63297;
  /* // Orchid Active */
  --color-orchid--active: #7f2879;

  /* // Iris */
  --color-iris: #5944c6;
  /* // Iris Active */
  --color-iris--active: #42389e;

  /* // Pink */
  --color-pink: #e61e64;
  /* // Pink Active */
  --color-pink--active: #bf1b5a;

  /* // Turquoise */
  --color-turquoise: #0eaba9;
  /* // Turquoise Active */
  --color-turquoise--active: #0c847e;

  /* // Apple */
  --color-apple: #91ba2f;
  /* // Apple Active */
  --color-apple--active: #759126;

  /* // Petrol */
  --color-petrol: #19657a;
  /* // Petrol Active */
  --color-petrol--active: #114551;

  /* // Filetype Colors */
  /* // -------------------------------------------------- */

  --color-file-excel: #008236;
  --color-file-powerpoint: #e86416;
  --color-file-word: #1781e3;
  --color-file-pdf: #d12;

  /* // Generic Colors */
  /* // -------------------------------------------------- */

  --color-black: #000;
  --color-disabled: #d8d8d8;

  /* // Selection Colors */
  /* // -------------------------------------------------- */

  --color-selection-fg: var(--color-white);
  --color-selection-bg: var(--color-gray);

  /* // Button Colors */
  /* // -------------------------------------------------- */

  --color-button-primary: var(--color-int-blue);
  --color-button-primary--active: var(--color-int-blue--active);
  --color-button-secondary: var(--color-int-gray);
  --color-button-secondary--active: var(--color-int-gray--active);
  --color-button-confirm: var(--color-int-green);
  --color-button-confirm--active: var(--color-int-green--active);

  /* // Link Colors */
  /* // -------------------------------------------------- */

  --color-link: var(--color-int-blue);
  --color-link--active: var(--color-int-blue--active);

  --color-link-dark: var(--color-white);
  --color-link-dark--active: var(--color-white);

  /* // Form Colors */
  /* // -------------------------------------------------- */

  /* // Input Fields */
  /* // ------------------------- */

  --color-input-bg: var(--color-white);
  --color-input-placeholder: var(--color-gray-light);

  --color-input-label: var(--color-gray-mid);

  --color-input-normal-form: var(--color-gray-20);
  --color-input-normal-text: var(--color-gray);

  --color-input-selected-form: var(--color-int-blue);
  --color-input-selected-text: var(--color-gray);

  --color-input-disabled-bg: var(--color-horizon-40);
  --color-input-disabled-form: var(--color-aluminium);
  --color-input-disabled-text: var(--color-gray-light);

  --color-input-invalid-form: var(--color-int-red--active);
  --color-input-invalid-text: var(--color-int-red);

  --color-input-success-form: var(--color-gray-mid);
  --color-input-success-text: var(--color-gray);
  --color-input-success-icon: var(--color-int-green);

  --color-input-autocomplete-bg: var(--color-white);
  --color-input-autocomplete-bg-hover: var(--color-horizon);
  --color-input-autocomplete-text: var(--color-gray);
  --color-input-autocomplete-text: var(--color-gray);

  /* // Radio Buttons */
  /* // ------------------------- */

  --color-radio-label: var(--color-gray);
  --color-radio-supporting: var(--color-gray-mid);

  --color-radio-empty: var(--color-gray-40);
  --color-radio-fill: var(--color-int-blue);
  --color-radio-fill-active: var(--color-int-blue--active);

  /* // Checkboxes */
  /* // ------------------------- */

  --color-checkbox-label: var(--color-gray);
  --color-checkbox-supporting: var(--color-gray-mid);

  --color-checkbox-empty: var(--color-gray-40);
  --color-checkbox-fill: var(--color-int-blue);
  --color-checkbox-fill-hover: var(--color-int-blue--active);
  --color-checkbox-focus: var(--color-int-blue);

  --color-checkbox-inverted-bg: var(--color-white);
  --color-checkbox-inverted-fill: var(--color-int-blue);
  --color-checkbox-inverted-fill-hover: var(--color-int-blue--active);
  --color-checkbox-inverted-focus: var(--color-int-blue--active);

  /* // Toggle Buttons */
  /* // ------------------------- */

  --color-toggle-label: var(--color-sc-gray);
  --color-toggle-label-inverted: var(--color-sc-white);
  --color-toggle-border: var(--color-gray-20);
  --color-toggle-active: var(--color-int-blue);
  --color-toggle-active-hover: var(--color-int-blue--active);

  /* // Card Colors */
  /* // -------------------------------------------------- */

  --color-card-bg: var(--color-white);
  --color-card-bg--active: #f0f5f7;
  --color-card-gray-bg: var(--color-horizon);
  --color-card-gray-bg--active: #e0e5e9;

  --color-card-navy: var(--color-navy);
  --color-card-blue: var(--color-blue);
  --color-card-purple: var(--color-orchid);
  --color-card-turquoise: var(--color-turquoise);
  --color-card-apple: var(--color-apple);
  --color-card-iris: var(--color-iris);
  --color-card-petrol: var(--color-petrol);
  --color-card-pink: var(--color-pink);

  /* // Table Colors */
  /* // -------------------------------------------------- */

  --color-table-text: var(--color-gray);
  --color-table-border: var(--color-gray-40);
  --color-table-header-dark: #f9fafc;

  /* // Loader Colors */
  /* // -------------------------------------------------- */

  --color-loader-bar-bg: #d8d8d8;

  /* // Tabs Colors */
  /* // -------------------------------------------------- */

  --color-tabs-light-border: var(--color-gray-20);
  --color-tabs-dark-border: var(--color-white);

  --color-tabs-light-indicator: var(--color-int-blue);
  --color-tabs-dark-indicator: var(--color-white);
  --color-tabs-dark-inverted-indicator: var(--color-navy);

  --color-tabs-light-text: var(--color-navy);
  --color-tabs-light-hover-text: var(--color-int-blue);
  --color-tabs-light-disabled-text: var(--color-gray);

  --color-tabs-dark-text: var(--color-white);
  --color-tabs-dark-hover-text: var(--color-white);
  --color-tabs-dark-disabled-text: var(--color-white);

  --color-tabs-dark-inverted-text: var(--color-navy);
  --color-tabs-dark-inverted-hover-text: var(--color-navy);
  --color-tabs-dark-inverted-disabled-text: var(--color-gray);

  --color-tabs-light-image-background: var(--color-horizon);

  /* // Accordion Colors */
  /* // -------------------------------------------------- */

  --color-accordion: var(--color-int-blue);
  --color-accordion--active: var(--color-int-blue--active);
  --color-accordion-border: var(--color-gray-20);

  /* // Navigation Colors */
  /* // -------------------------------------------------- */

  --color-nav-bg: var(--color-white);
  --color-nav-menu-bg: var(--color-horizon);
  --color-nav-footer-bg: #e3e9ec;

  --color-nav-divider: #dce3e7;

  --color-nav-link: var(--color-navy);
  --color-nav-link--active: var(--color-int-blue--active);

  --color-nav-link-alt: var(--color-gray-mid);
  --color-nav-link-alt--active: var(--color-int-blue--active);

  /* // Search Colors */
  /* // -------------------------------------------------- */

  --color-search-inactive-bg: var(--color-horizon);
  --color-search-light-bg: var(--color-blue-20);
  --color-search-dark-bg: var(--color-blue);

  --color-search-inactive-text: var(--color-gray-mid);
  --color-search-light-text: var(--color-gray);
  --color-search-dark-text: var(--color-white);

  --color-search-live-bg: var(--color-white);
  --color-search-live-text: var(--color-gray);
  --color-search-live-text-match: #0083ea;

  /* // Footer Colors */
  /* // -------------------------------------------------- */

  --color-footer-dark-bg: var(--color-gray-90);
  --color-footer-dark-bg-second: var(--color-gray);
  --color-footer-dark-divider: rgba(var(--color-white), 0.4);

  --color-footer-light-bg: var(--color-horizon);
  --color-footer-light-bg-second: var(--color-aluminium);
  --color-footer-light-divider: var(--color-gray-light);

  --color-footer-dark-link: var(--color-white);
  --color-footer-dark-link--active: var(--color-int-blue);

  --color-footer-light-link: var(--color-gray);
  --color-footer-light-link--active: var(--color-int-blue);

  --color-footer-light-link-top: var(--color-gray-mid);
  --color-footer-light-link-bottom: #3c3c3c;

  /* // Chart Colors */
  /* // -------------------------------------------------- */

  --color-chart-divider: #979797;
  --color-chart-bar-vertical-empty: #e0e6e9;

  /* // Comment Colors */
  /* // -------------------------------------------------- */

  --color-comment-border: var(--color-aluminium-80);
  --color-comment-dark-border: #8088aa;
}
