.wrapper {
  overflow: hidden;
  counter-reset: protocol-counter;
}

.item {
  display: flex;
  margin-bottom: 20px;
  position: relative;
}

.item:after {
  content: '';
  position: absolute;
  width: 2px;
  height: calc(100% + 20px);
  background-color: var(--color-gray-light);
  left: 11px;
}

.item.completed:after {
  background-color: var(--color-int-green);
}

.item:last-child:after {
  display: none;
}

.item .step {
  min-width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--color-white);
  border: 1px solid var(--color-int-blue);
  color: var(--color-int-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  margin-right: 10px;
  position: relative;
  z-index: 2;
}

.item .step:after {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  counter-increment: protocol-counter;
  content: counter(protocol-counter);
}

.item.completed .step {
  background-color: var(--color-int-green);
  color: var(--color-white);
  border: 1px solid var(--color-int-green);
}

.item.completed + .item:not(.completed):not(.rejected) .step {
  background-color: var(--color-int-blue);
  color: var(--color-white);
  border: 1px solid var(--color-int-blue);
}

.item.rejected .step {
  background-color: var(--color-int-red);
  color: var(--color-white);
  border: 1px solid var(--color-int-red);
}

.item .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}

.item .subconfirmation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.item .description {
  flex: auto;
}

.item .date {
  color: var(--color-gray-80);
}

.fleetActions {
  margin-top: 10px;
}

.noUnderline {
  border: none !important;
}

.bottomMargin {
  margin-bottom: 20px;
}

.reminders {
  margin-top: 16px;
}
