.container {
  width: 90%;
  max-width: 1000px;
  margin: 32px auto;
}

.header {
  display: flex;
  justify-content: flex-end;
  color: var(--color-int-blue);
  margin-bottom: 16px;
}

.header button {
  margin-left: 16px;
  display: flex;
  align-items: center;
}

.header button p {
  margin-left: 5px;
}

.archived {
  color: var(--color-sc-red);
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.archived span,
.restoreText {
  margin-left: 8px;
}
