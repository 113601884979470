.table__no-border{
    border-top: none !important;
    border-bottom: none !important;
}

.empty__outer {
    display: flex;
    justify-content: center;
    padding-top: 80px;
    padding-bottom: 80px;
}

.headingContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
