.title {
  font-size: 24px;
  color: var(--color-gray);
}

.link {
  color: var(--color-blue) !important;
}

.text {
  text-decoration: none !important;
  color: var(--color-gray-80) !important;
}

.container .card__item-wrapper p:hover {
  text-decoration: none !important;
}
