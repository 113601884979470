.radio_tab_container {
  display: flex;
}

.radio_tabs {
  height: 50px;
  width: fit-content;
  appearance: none;
  box-sizing: border-box;
}

.radio_tabs_label {
  margin: 0 15px 0 0;
  padding: 0 15px;
  line-height: 50px;
  border: 1px solid var(--color-gray-20);
  border-radius: 5px;
  height: 50px;
  cursor: pointer;
  color: var(--color-black);
}

.radio_tabs_label_selected {
  border: 1px solid var(--color-blue);
  color: var(--color-blue);
}
