.container{
  width: 25%;
  min-height: 100%;
  border-right: 1px solid var(--color-gray-20);
  color: var(--color-navy);
  padding-top: 32px;
}

.filterContainer {
  margin-top: 32px;
}

.filterContainer:first-child {
  margin-top: 0;
}

.filterContainer h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.filterContainer button{
  margin-bottom: 10px;
  width: 100%;
}

.active {
  text-decoration: underline;
}

.filterContainer p:hover {
  text-decoration: underline;
}
