.assigneeText {
  color: var(--color-gray) !important;
  width: calc(100% - 50px);
  white-space: break-spaces !important;
  margin-top: 5px !important;
}

.date {
  color: var(--color-gray-80);
}

.lineThrough {
  text-decoration: line-through;
}
