.input{
  margin-top: 0 !important;
}

.subTitle {
  margin-bottom: 32px;
  margin-top: 64px;
}

.subTitle:first-child{
  margin-top: 0;
}

.cruiseControlField{
  margin-top: 16px;
}

.towableMass {
  margin-top: 8px;
}

.deleteFile {
  cursor: pointer;
  margin-left: 32px;
  color: var(--color-blue);
}

.file {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
