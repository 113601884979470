.warning {
  color: var(--color-int-orange);
  display: flex;
  align-items: center;
}

.warning .content {
  white-space: nowrap;
  margin-right: 10px;
}

.tooltip {
  color: var(--color-int-orange) !important;
  font-size: 16px;
  opacity: 1 !important;
  padding: 20px;
  border: 1px solid var(--color-toggle-border) !important;
}

.tooltip:before {
  border-top-color: var(--color-toggle-border) !important;
}

.icon {
  margin-right: 5px;
}
