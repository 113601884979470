.badge {
  left: 100% !important;
}

.badge:hover {
  background-color: var(--color-blue) !important;
}
.disLinkStyling {
  font-weight: 600 !important;
}
.disLinkStyling:hover {
  color: var(--color-int-blue);
}
