.row:hover {
  background-color: var(--color-horizon);
  cursor: pointer;
}

.row td .minHeight {
  min-height: 38px;
  display: flex;
  align-items: center;
}
