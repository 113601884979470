.CategoryPicker__price {
  color: var(--color-gray-80);
}

.CategoryPicker__delimiter:before {
  content: '-';
  margin-left: 10px;
  margin-right: 10px;
}

.Select__option--is-selected .CategoryPicker__price {
  color: var(--color-white);
}
