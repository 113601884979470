.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}
.header h1 {
  margin-bottom: 0;
}

.editLink {
  display: flex;
}

.headerButton {
  display: flex;
  justify-self: flex-end;
  background-color: transparent !important;
  color: var(--color-blue) !important;
}

.buttonContainer {
  display: flex;
}

@media (min-width: 1024px) {
  .propertyWrapper {
    max-width: calc(50%);
  }
}
