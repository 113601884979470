.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.header h1 {
  margin-bottom: 0 !important;
}

.header button {
  margin-right: 0 !important;
}
