.wrapper {
  display: flex;
}

.page {
  min-width: 40px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  padding: 5px;
}

.page.disabled a {
  color: var(--color-disabled) !important;
}

.activePage {
  background-color: var(--color-blue);
  border-radius: 5px;
}

.page a {
  transition: none !important;
  border-bottom: none !important;
}

.activePage a,
.activePage a:focus,
.activePage a:active,
.activePage a:hover {
  color: var(--color-white) !important;
}
