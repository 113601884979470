.card {
  cursor: pointer;
}

.cardIcon {
  font-size: 32px !important;
  color: var(--color-int-blue);
}

.categoryCard {
  width: calc(50% - 10px);
  margin-right: 20px;
  margin-bottom: 20px;
}

.cardContent {
  color: var(--color-gray-mid);
}

.card:hover .cardIcon {
  color: var(--color-int-blue--active);
}

.pdfLink::before {
  content: '\E07B\ '; /* PDF Icon + Whitespace */
  font-family: 'sdx-icons';
}

.archived {
  color: var(--color-blue);
  font-weight: 600;
  margin-top: 16px;
  display: flex;
}
