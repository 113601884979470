.contractconflict {
  position: relative;
  display: flex;
  align-items: center;
  width: 200px;
}

.errorButton {
  position: absolute !important;
  top: 20px;
  left: 0;
  justify-self: flex-end;
  background-color: transparent !important;
  color: var(--color-sc-red) !important;
  padding-left: 0 !important;
  padding-top: 10px !important;
  margin: 0 !important;
}
