.specs {
  width: 50%;
}

.specs li {
  margin-bottom: 0 !important;
}

.specs li:nth-child(n + 4) {
  display: none;
}

.toggleExpanded {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--color-gray-20);
}

.toggleExpanded button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggleExpanded button span {
  margin-right: 5px;
}

.container {
  padding: 30px 24px;
}

.expanded .specs li:nth-child(n + 4) {
  display: list-item;
}

.specs li span:first-child {
  display: inline-block;
  width: 250px;
}

.emptyState {
  height: 294px;
}

@media (max-width: 768px) {
  .specs {
    display: block;
    width: 100%;
  }

  .specs > div {
    margin-top: 8px;
  }

  .specs li span:first-child {
    width: auto;
  }

  .specs li span:nth-child(2)::before {
    content: ': ';
    white-space: pre;
  }
}

.pdfLink::before {
  content: '\E07B\ '; /* PDF Icon + Whitespace */
  font-family: 'sdx-icons';
}
