h1,
h2,
h3,
h4 {
  color: var(--color-navy) !important;
  font-weight: 300 !important;
}

.card--document .card__header {
  font-weight: 300 !important;
}

.nav__secondary {
  padding-top: 0 !important;
  display: flex;
  align-items: center;
  height: 100%;
}

/* Remove underline from p and add it to a in card--document */
.card--document .card__item .card__item-wrapper p:hover {
  text-decoration: none !important;
}

.card--document .card__item .card__item-wrapper a:hover {
  text-decoration: underline !important;
}

.card--document .card__item p,
.card--document .card__item a {
  font-size: 18px !important;
}

.link {
  color: var(--color-blue) !important;
}

.card--document .card__item {
  color: var(--color-gray-80);
}

.input-field {
  margin-bottom: 0;
}

.input-field input,
textarea {
  border: 1px solid var(--color-gray-light) !important;
}

.modal__body {
  overflow-y: visible;
}

.modal__content {
  overflow: visible;
}

.align-right {
  text-align: right;
  width: 200px;
  box-sizing: content-box;
  flex: 0;
}
