.loading .loadingSpinner,
.error .icon,
.content .icon {
  margin-right: 10px;
}

.loading {
  color: var(--color-int-blue);
}

.error {
  color: var(--color-int-orange);
}

.content {
  color: var(--color-int-green);
}
