.section {
  margin-bottom: 70px;
}

.cardText {
  padding: 22px 24px 17px;
}

.cardSeparator i {
  font-size: 32px;
  padding: 0 10px;
}

.mobileSwitch {
  display: none !important;
}

.mobileSwitch ~ .mobileSwitch {
  display: inherit !important;
}

@media (max-width: 768px) {
  .process {
    flex-direction: column;
    align-items: center;
  }

  .process > div {
    margin-top: 8px;
  }

  .mobileSwitch {
    display: inherit !important;
  }

  .mobileSwitch ~ .mobileSwitch {
    display: none !important;
  }
}
