.tooltip {
  font-size: 16px;
  opacity: 1 !important;
  padding: 20px;
  border: 1px solid var(--color-toggle-border) !important;
}

.tooltip:before {
  border-top-color: var(--color-toggle-border) !important;
}

.label {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--color-int-orange) !important;
}

.label > span {
  text-decoration: underline;
}

.labelIcon {
  margin-right: 5px;
}

.wrapper {
  display: inline-block;
}

.historyItem {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.historyItem:last-child {
  margin-bottom: 0;
}

.historyItemTitle {
  font-size: 16px !important;
  font-weight: normal;
  color: var(--color-blue);
}

.historyItemContent {
  font-weight: normal;
  max-width: 400px;
}
