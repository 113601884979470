.header {
  display: flex;
  justify-content: space-between;
}

.headerButton {
  display: flex;
  justify-self: flex-end;
  background-color: transparent !important;
  color: var(--color-blue) !important;
}

.headerButton:nth-of-type(2n) {
  padding-right: 0;
}

.editLink, .deleteLink {
  display: flex;
  justify-self: flex-end;
  background-color: transparent !important;
  color: var(--color-blue) !important;
}

.buttonContainer {
  display: flex;
}

@media (min-width: 1024px) {
  .propertyWrapper {
    max-width: 50%;
  }
}
