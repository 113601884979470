.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container span {
  display: inline-block;
  margin-right: 5px;
}

.container .icon {
  font-size: 14px;
}

.langItem {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-int-blue);
  padding: 12px 0 !important;
  background-color: var(--color-white);
  cursor: pointer;
  border-bottom: 1px solid var(--color-gray-20) !important;
  width: 100%;
}

.langItem:focus {
  outline: 2px solid Highlight;
}

.langItem:hover {
  color: var(--color-white);
  background-color: var(--color-int-blue);
}

.langItem:after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: var(--color-int-blue);
  bottom: 0;
  left: 0;
  height: 100%;
}
