.interiorCardItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 24px;
}

.interiorCardItem span{
  color: var(--color-navy);
}

.chevron {
  margin-left: auto;
}

.edit {
  margin-left: 8px;
}

