.vehicleTypeBox {
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 150px;
  padding: 10px;
  border: 1px solid var(--color-gray-20) !important;
  border-radius: 5px;
  color: var(--color-gray-80) !important;
  font-size: 16px;
  background-color: white;
  width: 100%;
  margin-bottom: 10px !important;
  cursor: pointer;

  transition: border 200ms var(--sdx-easing), border-color 200ms var(--sdx-easing),
    color 200ms var(--sdx-easing), box-shadow 200ms var(--sdx-easing);
}

.vehicleTypeBox:hover,
.vehicleTypeBox:focus {
  border-color: var(--color-gray-20) !important;
  color: var(--color-int-blue);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
}
