.item {
  padding: 20px 0;
  border-bottom: 1px solid #dde3e7;
}

.item:last-child {
  border-bottom: none;
  padding-bottom: 10px;
}

.item:first-child {
  padding-top: 5px;
}

.commentHeading {
  color: var(--color-gray) !important;
  font-weight: 600;
}

.text {
  margin-top: 5px !important;
  color: var(--color-gray) !important;
  overflow: initial !important;
  white-space: normal !important;
}
