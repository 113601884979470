.field {
  margin-bottom: 20px;
}

.field > label {
  font-size: 18px;
  margin-bottom: 9px;
  color: var(--color-gray);
  font-weight: 400;
}

.field > label.required:after {
  content: '*';
}

.bold {
  font-weight: 600 !important;
}
