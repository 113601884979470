.wrapper {
  text-align: center;
}

.status {
  margin-bottom: 30px;
}

.spinner {
  display: inline-block;
}

.preview {
  width: 100%;
  height: calc(100vh - 230px);
}
