.image,
.placeholder {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  position: absolute;
}

.image {
  background-position: center;
  background-size: cover;
}

.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
}
