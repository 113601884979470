.container:before {
  font-size: 28px !important;
  margin-right: 8px;
}

.document:before {
  color: var(--color-pink);
}

.document {
  border-bottom: none !important;
  color: var(--color-gray) !important;
}

.link {
  border-bottom: none !important;
}
