.filter {
  margin-top: 29px;
}

.orderlist {
  margin-top: 30px;
  margin-bottom: 20px;
}

.orderlist table {
  border-top: none !important; /* SDX */
}

.orderlist table tbody td {
  white-space: normal;
}

.orderlist table thead td {
  padding-bottom: 17px;
}

.empty {
  border-bottom: none !important;
}

.empty__outer {
  display: flex;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

.circle {
  width: 48px;
  height: 48px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: var(--color-blue);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
