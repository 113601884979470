.egg_container {
  width: 100px;
  height: 25px;
  position: absolute;
  bottom: 0;
  left: -100px;
  animation: hyperdrive 9s ease-in 1;
  animation-delay: 1s;
}

.egg_container img {
  height: 25px;
}

@keyframes hyperdrive {
  0%   {left:-100px;}
  50%   {left:20px;}
  100% {left: 100%;}
}
