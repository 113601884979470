.fileUpload {
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.fileUploadContainer {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.fileUploadContainer label {
  border: 0;
  border-bottom-style: none;
  border-color: var(--color-blue) !important;
  background-color: var(--color-blue) !important;
  color: var(--color-white) !important;
  line-height: 24px;
  letter-spacing: -0.1px;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  display: inline-block;
  position: relative;
  transition: all 70ms cubic-bezier(0.4, 0, 0.6, 1);
  margin: 0 16px 0 0;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 12px 24px;
  min-width: 160px;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 0 0;
  transform-origin: 50% 50%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.file {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.deleteFile {
  cursor: pointer;
  margin-left: 32px;
  color: var(--color-blue);
}

.newFileUpload {
  margin-top: 0;
}
