.spinner__outer {
  margin-top: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dateTooOld {
  margin-bottom: 8px;
  display: block;
}

.dateTooOld span {
  color: var(--color-sc-red);
  text-decoration: line-through;
}
