.lifeform {
  background-size: 3600px 40px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url(../../assets/images/lifeform-spritesheet.png);
  width: 40px;
  height: 40px;
  backface-visibility: hidden;
  min-width: 40px;
  animation: repeatingAnimation 30s steps(90) infinite, initialAnimation 6s steps(90);
}

.lifeform,
.type {
  display: inline-block;
  position: relative;
  left: 0;
  top: 0;
  height: 40px;
}

@keyframes repeatingAnimation {
  0% {
    background-position: 0;
  }
  80% {
    background-position: 0;
  }
  100% {
    background-position: -3600px;
  }
}

@keyframes initialAnimation {
  100% {
    background-position: -3600px;
  }
}

@media all and (max-width: 1023px) {
  .logo {
    margin-right: 50px;
  }
}
