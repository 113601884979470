.row:hover {
  background-color: var(--color-horizon);
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h1 {
  margin-bottom: 32px !important;
}
