.wrapper {
  background-color: var(--color-horizon);
}

.container {
  height: 80px;
  display: flex;
  align-items: center;
}

.container a[href] {
  color: var(--color-navy);
  border-bottom: none;
  display: flex;
  align-items: center;
}

.container a[href]:hover {
  color: var(--color-int-blue--active);
}

.container a[href] i {
  font-size: 28px;
  display: inline-block;
  margin-right: 10px;
}
