.modal {
  max-width: 100%;
  overflow-y: scroll;
  pointer-events: all !important;
  max-height: 100%;
}

.modal--small {
  max-width: 400px;
}

.modal--medium {
  width: 700px;
}

.modal--wide {
  width: 1000px;
}

.modalbody {
  max-height: initial !important;
  background: var(--color-white);
}
